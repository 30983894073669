import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageRecruit = ({ data, location }) => {
  const intl = useIntl();

  const post = data.contentfulRecruit;

  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Careers at Cyberdyne`
            : `Karriere bei Cyberdyne`
        }
        description={
          intl.locale === "en"
            ? `Join our  team  and work for a growing company using revolutionary medical technology. This is where individual envision  gather together, committing to the values that lead to better society.`
            : `Werden Sie Teil unseres Teams, einer wachsenden Firma, die neuartige medizinische Technologien nutzt. Unsere Visionen verpflichten sich einer besseren Gesellschaft.`
        }
      />
      <section className="sect bg-bls">
        <div className="container container-newslist">
          <h1 className="h1">Wir suchen:</h1>

          <p className="message-label-bold txt txt-rot mb-8">Recruting</p>

          <div className="flex-row justify-content-between">
            <div className="flex-column-6">
              <h2 className="h3 txt font-weight-semibold">{post.title}</h2>
              <div
                className="body-text-recruit"
                dangerouslySetInnerHTML={{
                  __html: post.content ? post.content.childMarkdownRemark.html : ""
                }}
              />
            </div>
            <div className="flex-column-5">
              <p className="message-label-bold txt txt-rot mb-8">Info</p>
              <p>
                <span className="fwb">Unternehmen:</span>
                <br />
                {post.unternehmen}
                <br />
                <br />
                <span className="fwb">Erfahrungsniveau:</span>
                <br />
                {post.erfahrungsniveau}
                <br />
                <br />
                <span className="fwb">Jobtyp:</span>
                <br />
                {post.jobtyp}
                <br />
                <br />
                <span className="fwb">Primärer Standort:</span>
                <br />
                {post.primrerStandort}
                <br />
                <br />
                <span className="fwb">Bevorzugter Arbeitsort:</span>
                <br />
                {post.bevorzugterArbeitsort}
                <br />
                <br />
                <span className="fwb">Arbeitszeit:</span>
                <br />
                {post.arbeitszeit}
              </p>

              <p className="message-label-bold txt txt-rot mb-8">Bewerbung</p>

              <div
                className="body-text-recruit"
                dangerouslySetInnerHTML={{
                  __html: post.bewerbung ? post.bewerbung.childMarkdownRemark.html : "" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 txt mb-5">
            {intl.locale === "en" ? `Media Room` : `Multimedia`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  News, pressrelease, event, exhibition
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The significant existence of technology is to support, assist,
                  enhance, and regenerate functions of humans.
                </p>
                <Link to="/news/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Clinical Publications`
                    : `Klinische Studien`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We envision a future where humans and technology are
                  seamlessly interfaced, feeding off of each other.
                </p>
                <Link to="/research/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};

export default PageRecruit;

export const pageQuery = graphql`
  query ($slug: String, $language: String) {
    contentfulRecruit(
      slug: { eq: $slug },
      node_locale: { eq: $language
    }) {
      primrerStandort
      slug
      title
      arbeitszeit
      bevorzugterArbeitsort
      bewerbung {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      erfahrungsniveau
      jobtyp
      unternehmen
    }
  }
`;
